import OSS from 'ali-oss'
 
export const client = new OSS({
	
	region: 'oss-cn-hangzhou', // oss地址
	accessKeyId: 'LTAI5tH6vzsmQhNikQUtEXpD', // 通过阿里云控制台创建的AccessKey ID。
	accessKeySecret: 'S3BQDGhLNHaHz1JxMJ2Rd9nKOBvZn3', // 通过阿里云控制台创建的AccessKey Secret。
	bucket: 'picture-file-public-read-dt', // 仓库名字
	
    useFetch: true, // 支持上传大于100KB的文件
    secure:true, // 返回的url为https
    timeout:10000000,//响应时间
})

export const headers = {
    // 指定该Object被下载时网页的缓存行为。
    "Cache-Control": "no-cache",
    // 指定该Object被下载时的名称。
    "Content-Disposition": "inline",
    // 指定该Object被下载时的内容编码格式。
    "Content-Encoding": "UTF-8",
    // 指定过期时间。
    // Expires: "Wed, 08 Jul 2023 16:57:01 GMT",
    // 指定Object的存储类型。
    "x-oss-storage-class": "Standard",
    // 指定Object的访问权限。
    // "x-oss-object-acl": "private",
    // 设置Object的标签，可同时设置多个标签。
    "x-oss-tagging": "Tag1=1&Tag2=2",
    // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    "x-oss-forbid-overwrite": "true",
    "secure": "true"
};