/**
 * 常用工具库
 */


/**
 * 系统时间
 * 格式：YYYY-MM-DD hh:mm:ss
 * 使用：
 * 使用方法：
 * import {getDayTimer} from "../utils/tools.js"
 * getDayTimer(new Date())
 * 
 */
function getDayTimer(data) {
	var date = new Date(data)
	var y = date.getFullYear()
	var m = date.getMonth() + 1
	m = m < 10 ? ('0' + m) : m
	var d = date.getDate()
	d = d < 10 ? ('0' + d) : d
	var currentdate = y + '-' + m + '-' + d;
	var hh = date.getHours()
	hh = hh < 10 ? ('0' + hh) : hh
	var mm = date.getMinutes()
	mm = mm < 10 ? ('0' + mm) : mm
	var ss = date.getSeconds()
	ss = ss < 10 ? ('0' + ss) : ss
	var time = hh + ':' + mm + ':' + ss;
	return currentdate + " " + time
}

const Debounce = (fn, t) => {
	let delay = t || 1000;
	let timer;
	return function() {
	  let args = arguments;
	  if (timer) {
		clearTimeout(timer);
	  }
  
	  let callNow = !timer;
  
	  timer = setTimeout(() => {
		timer = null;
	  }, delay);
  
	  if (callNow) fn.apply(this, args);
	};
  };

/**
 * 封装
 * 阿里云OSS上传
 * httpRequest 
 * 参数：file 文件名，src 服务器的目录
 */
import {client,headers} from '@/tools/oss.js'
 function httpRequest(file,src,fn) { 
	console.log(file)
	console.log(src)
	console.log(fn)

	//判断扩展名
	const tmpcnt = file.file.name.lastIndexOf(".") // 获取.的下标
	const uploadFileName = file.file.name.substring(0, tmpcnt) // 获取文件名
	// console.log(uploadFileName, '文件名');
	const exname = file.file.name.substring(tmpcnt + 1) // 获取后缀名
	// console.log(exname, '后缀名');
	const names = ['jpg', 'jpeg', 'png', 'bmp', 'rar', 'zip','pdf','xlsx','docx','txt']
	if (names.indexOf(exname.toLowerCase()) < 0) {
		this.$message.error("文件格式不支持!")
		return false
	}
	// user_register// 用户注册
	// sign_up_audit// 审核 
	const time = Date.now();
	const year = new Date().getFullYear();
	const fileName = "dt_cup/" + src + "/" + year + '/' + uploadFileName + time + '.' + exname;
	// console.log(fileName, '文件名');
	client.put(fileName, file.file, {
		headers
	}, ).then(res => {
		// 上传成功回调
		console.log(res, 'res--成功回调');
		// fn(res)
		return fn(res);
	}).catch(err => {
		console.log(err, 'err');
		return res;
	})
}



// 方法统一抛出
export {
	getDayTimer,
	httpRequest,
	Debounce
};
